import React from "react";
import "aos/dist/aos.css";
import "./style.css"

const Skills = () => {
    const items = [
        { category: "Programming Languages", details: "Java, Python, JavaScript" },
        { category: "Frameworks", details: "Spring Boot, Spring Security, Spring AI, Django, Flask" },
        { category: "Backend", details: "JEE, Microservices, Feign Client, RabbitMQ (AMQP), Kafka" },
        { category: "Frontend", details: "ReactJS (TSX, JSX, Redux), Tailwind, PrimeReact, Angular, Bootstrap" },
        { category: "Databases", details: "SQL, PL/SQL, DBMS (Oracle, MySQL), Firebase" },
        { category: "DevOps", details: "Docker, Jenkins, GitHub Actions, AWS (EC2, S3)" },
        { category: "Tools", details: "IntelliJ, VSCode, Postman" },
        { category: "Version Control", details: "Git, GitHub" },
        { category: "Modeling", details: "UML, Merise (MCD)" },
        { category: "Testing & Code Quality", details: "SonarQube, JUnit" },
        { category: "Project Management", details: "Jira, Confluence, Agile (Scrum/Kanban)" },
        { category: "Other", details: "Portainer, Swagger, Redis" },
    ];

    
    return (<>
        <section
            id="skills"//[rgb(225,228,228)]
            className="bg-white dark:bg-gray-700 pt-10 pb-10"
        >

            {/* 2. ============= Skills ============= */}

            <div className="container flex justify-center">
                <div className="experience-card bg-gray-100 dark:bg-[rgb(24,26,27)] 
      w-full md:w-[750px] mt-10 pb-10 px-4 lg:px-7 border-2 border-[#2fb8b8] 
      shadow-lg dark:shadow-xl shadow-black flex">

                    {/* Vertical Line (Left Side) */}
                    <div className="vertical-line mt-4 bg-primaryColor h-full w-1"></div>

                    {/* Content Section */}
                    <div className="content-section sm:px-2 md:px-2 lg:px-7 mt-6">
                        {/* Header Section */}
                        <h1 className="text-center text-3xl font-semibold dark:text-[rgb(94,191,221)] pb-5">
                            <span className="border-b-4 border-primaryColor pb-2">
                                SKILLS
        </span>
                        </h1>

                        {/* Experience Entries */}
                        <div className="space-y-8">

                            {/* TECHNICAL SKILLS */}
                            <div className="text-gray-800 dark:text-white dark:bg-[rgb(24,26,27)]">
                                <ul className="list-disc dark:text-white pl-5">
                                    {items.map((item, index) => (
                                        <li key={index} className="pb-2">
                                            <b>{item.category}:</b> {item.details}.
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    </>
    );
};

export default Skills;