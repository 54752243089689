import React from "react";
import "aos/dist/aos.css";
import "./style.css"

const Education = () => {
//bg-[rgb(225,228,228)]
    return (<>
        <section
            id="education"
            className="bg-white dark:bg-gray-700 pt-10 pb-10"
        >


            {/* 3. ============= Education ============= */}

            <div className="container flex justify-center transform transition ease-in">
                <div className="experience-card bg-gray-100 dark:bg-[rgb(24,26,27)] 
                    w-full md:w-[750px] mt-10 pb-10 px-4 lg:px-7 
                    border-2 border-[#2fb8b8] shadow-lg 
                    dark:shadow-xl shadow-black flex">

                    {/* Vertical Line (Left Side) */}
                    <div className="vertical-line mt-4 dark:bg-[rgb(24,26,27)] h-full w-1"></div>

                    {/* Content Section */}
                    <div className="content-section sm:px-0 md:px-2 lg:px-7">
                        {/* Header Section */}
                        <h1 className="text-center text-3xl font-semibold dark:text-[rgb(94,191,221)] pb-5">

                            <span className="border-b-4 border-primaryColor pb-2"><br />EDUCATION</span>
                        </h1>

                        {/* Education Entries */}
                        <div className="space-y-8">
                            {/* Master Degree Section */}
                            <div className="education-entry">
                                <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400">Master of Intelligent and Decision-making Systems [Bac+5] (MSID)</h2>
                                <p className=" group-first-of-type:group-last-of-type: dark:text-gray-900">
                                    <a href="#" target="_blank" rel="noopener noreferrer" 
                                    className="text-gray-800 hover:underline">
                                        Faculty of Sciences – Sidi Mohamed ben Abdellah Fez University
                </a>
                                </p>
                                <p className="text-primaryColor dark:text-slate-100">(2021 - 2023)</p>
                            </div>

                            {/* Bachelor Degree Section */}
                            <div className="education-entry">
                                <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400">Bachelor of Science in Mathematics and Computer Science (SMI)</h2>
                                <p className=" dark:text-gray-900">
                                    <a href="#" target="_blank" rel="noopener noreferrer" 
                                    className="text-gray-800 hover:underline">
                                        Faculty of Sciences - Moulay Ismail Meknes University
                </a>
                                </p>
                                <p className="text-primaryColor dark:text-slate-100">(2015 - 2019)</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </section>
    </>
    );
};

export default Education;
