import React, { useState, useEffect } from "react";
import "aos/dist/aos.css";
import "../../styles/modal.css";
import Img2 from "../../assets/images/atyp11-min.png";
import Img41 from "../../assets/images/aideat3.PNG";
import Img5 from "../../assets/images/jimmy.png";
import Img7 from "../../assets/images/focusai.png";
import Img6 from "../../assets/images/cabinet.png";
import videe from "../../assets/images/isto.jpg";
import ImgNov from "../../assets/images/nova.png";
import Modal from "react-modal";

const Portfolio = () => {
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  const [selectedKeyword, setSelectedKeyword] = useState('java');

  const cards = [
    {
      keywords: ['data science'],
      title: 'AI Assistant Platform',
      description: "In 2023, Developed an innovative platform leveraging Artificial Intelligence and Natural Language Processing (NLP) to automate complex business tasks for clients, enhancing operational efficiency.",
      imageSrc: Img41,
      tech: "NLP, Django, Python, LLMs, PostgreSQL, Redis, ReactJS, AWS (EC2, S3), Docker.",
    },
    {
      keywords: ['data science'],
      title: 'Intelligent Chatbot Development',
      description: 'In 2023, Built multiple intelligent chatbots using cutting-edge technologies such as HuggingFace, LLMs, and PDF data processing, providing seamless and contextual user interactions.',
      imageSrc: videe,
      tech: "LLMs, Llama 2, GPT-4, HuggingFace, LangChain, Flowise.",
    },
    {
      keywords: ["java", "ReactJS","spring"],
      title: "Warehouse Management Software",
      description: "In 2024, Developed in collaboration with an international team to design and implement a large-scale microservices software for warehouse management. Utilized Spring Boot, Java 21 and ReactJS, integrated CI/CD pipelines using Jenkins and SonarQube, and developed modules to optimize supply chain operations.",
      imageSrc: videe,
      tech: "Spring Boot, Java 21, Microservices, Jenkins, SonarQube, Docker"
    },
    {
      keywords: ["java", "ReactJS", "spring"],
      title: "Gym Management Web Application",
      description: "In 2024, Created a full-stack web application to streamline gym operations. The platform offers features such as membership management, class scheduling, and payment tracking, delivering an intuitive user experience for gym owners and clients.",
      imageSrc: videe,
      tech: "Spring Boot, ReactJS, PostgreSQL, Tailwind CSS"
    },
    {
      keywords: ['java', 'ReactJS', 'spring'],
      title: 'Accommodation Booking Application',
      description: "In 2020,  Designed and implemented a user-friendly booking platform featuring date selection and secure online payment integration via PayPal, enabling seamless reservations.",
      imageSrc: Img2,
      tech: "Spring Boot, ReactJS, Java, Spring Security, Spring Data, PostgreSQL, JUnit, Heroku, JWT, Redux.",
    },
    {
      keywords: ['java', 'jee'],
      title: 'Web Agency Website',
      description: "In 2023, Developed a comprehensive web platform for a digital agency, showcasing their services and facilitating enhanced client communication.",
      imageSrc: ImgNov,
      tech: "ReactJS, Firebase, Tailwind CSS, HTML5.",
    },
    {
      keywords: ['java', 'jee'],
      title: 'Laboratory Management Web Application',
      description: "In 2023, Built a specialized platform for researchers, streamlining collaboration, research filtering, and article management. Features include annual report generation and printing functionalities.",
      tech: "Java, Jakarta EE (J2EE), MySQL, JSP, MVC, Bootstrap, HTML5.",
      imageSrc: videe,
    },
    {
      keywords: ['java', 'spring'],
      title: 'Medical Office Management Website',
      description: "In 2021, Created a platform to simplify medical office operations, including patient record management, prescription generation, chat systems, and invoice management. The platform also supports comprehensive report printing.",
      imageSrc: Img6,
      tech: "Spring Boot, Java, JSP.",
    },
    {
      keywords: ['java', 'ReactJS'],
      title: 'Fitness Back-office Web Application',
      description: "In 2023, Developed a robust back-office application for a fitness website, facilitating the management of packages, subscriptions, users, and other site components.",
      imageSrc: Img5,
      tech: "ReactJS, Firebase, Tailwind CSS, HTML5.",
    },
    {
      keywords: ['java', 'ReactJS'],
      title: 'Web Agency Internet Platform',
      description: "In 2023, Engineered a professional platform for a web agency to present their services effectively and streamline client interactions.",
      imageSrc: Img7,
      tech: "Angular, Firebase, Tailwind CSS, HTML5.",
    },
  ];
  
  const filteredCards = cards.filter((card) =>
    selectedKeyword ? card.keywords.includes(selectedKeyword) : true
  );
  return ( //[rgb(225,228,228)] 
    <section id="portfolio" className="bg-white
    dark:bg-gray-700 pt-10 pb-10 ">
      <div className="experience-card bg-gray-100 dark:bg-[rgb(24,26,27)] 
        sm:m-4 md:m-14 lg:m-14 border-2 border-[#2fb8b8] 
      shadow-lg dark:shadow-xl shadow-black ">
        <div className="container mt-2 pt-4 pb-4 ">



          <div className="text-center mb-10">
            <h1 className="text-center text-3xl font-semibold dark:text-[rgb(94,191,221)] pb-5">
              <span className="border-b-4 border-primaryColor pb-2">
                PROJECTS
        </span>
            </h1>

            <p className="lg:max-w-[1000px] lg:mx-auto text-headingColor dark:text-white font-[500] text-[18px] leading-7 pt-6">
              Here you'll find a collection of my projects.   <br /></p>
              <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 mb-4 justify-center text-center mt-2">
         
              <button
                onClick={() => setSelectedKeyword('java')}
                className={`transition duration-50 ease-in-out px-4 py-2 rounded ${selectedKeyword === 'java'
                  ? 'bg-blue-200 text-gray-900'
                  : 'bg-gray-300 text-gray-700 hover:bg-blue-400 hover:text-white'
                  }`}
              >
                Web Projects Collection
                <b className="text-[#7a3737] pl-2">[8+]</b> 
  </button>

              <button
                onClick={() => setSelectedKeyword('data science')}
                className={`transition duration-50 ease-in-out px-4 py-2 rounded ${selectedKeyword === 'data science'
                  ? 'bg-blue-200 text-gray-900'
                  : 'bg-gray-300 text-gray-700 hover:bg-blue-400 hover:text-white'
                  }`}
              >
                AI Projects Collection
                <b className="text-[#7a3737] pl-2">[2+]</b>
  </button>
            </div>

          </div>

        </div>

        {/* Container */}
        <div className="container mx-auto flex flex-wrap justify-center 
                      gap-4 md:gap-8 lg:gap-12 xl:gap-16 2xl:gap-20 pb-6" >


          {filteredCards.map((card, index) => (
            <div key={index} className="dark:bg-gray-200 dark:text-black
          bg-[#013246] text-black hover:bg-[#121e38]
          hover:scale-105 max-w-sm dark:border-white 
          rounded-2xl hover:shadow-md mb-2">
              {card.imageSrc != null && (
                <img
                  className="cursor-pointer w-full h-[210px] transition duration-300 ease-in-out rounded-lg pt-1 px-1"
                  src={card.imageSrc}
                  alt=""
                />)}

              <div className="p-5 text-center">
                <h5 className="mb-2 text-xl font-bold tracking-tight text-primaryColor dark:text-primaryColor">
                  {card.title}
                </h5>
                <p className="mb-3 font-normal 
              text-gray-200 dark:text-black ">
                  {card.description}
                </p>
                {card.link && (
                  <>
                    <a href={card.link} target="blank"
                      className="text-gray-200 ">
                      <b><u>Show description</u></b></a>
                    <br />
                  </>)

                }
                <br /><b className="dark:bg-gray-200 text-gray-200 dark:text-primaryColor">
                  Technologies used : </b>
                <span className="text-gray-100 dark:text-gray-900">{card.tech}</span>
              </div>
            </div>
          ))}

        </div>
      </div>
    </section>
  );
};

export default Portfolio;
